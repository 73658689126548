import React, { useEffect, useState } from "react";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import * as Babel from "@babel/standalone";
import * as RadixThemes from "@radix-ui/themes";
import * as RadixIcons from "@radix-ui/react-icons";
import * as RadixColors from "@radix-ui/colors";
import * as LucideIcons from "lucide-react";
import * as ReCharts from "recharts";
import * as Mafs from "mafs";
import "mafs/core.css";
import "mafs/font.css";
import { ErrorBoundary } from "./ErrorBoundary";

function App() {
  const [Component, setComponent] = useState<React.ComponentType | null>(null);

  useEffect(() => {
    window.parent.postMessage(
      {
        kind: "INIT_COMPLETE",
      },
      "*"
    );

    const handleMessage = (event: MessageEvent) => {
      console.log({ origin: event.origin });
      if (event.data.kind !== "ARTIFACT_RENDER_REQUEST") return;
      const { code, type } = event.data;

      try {
        const presets = ["react", ["env", { modules: "commonjs" }]];
        if (type.endsWith("+tsx")) presets.push("typescript");

        const transformedCode = Babel.transform(code, {
          presets,
          filename: type.endsWith("+tsx") ? "virtual.tsx" : "virtual.jsx",
        }).code;
        console.log({ transformedCode });

        const module = { exports: {} };
        const require = (moduleName: string) => {
          if (moduleName === "mafs") return Mafs;
          if (moduleName === "react") return React;
          if (moduleName === "recharts") return ReCharts;
          if (moduleName === "lucide-react") return LucideIcons;
          if (moduleName === "@radix-ui/themes") return RadixThemes;
          if (moduleName === "@radix-ui/colors") return RadixColors;
          if (moduleName === "@radix-ui/react-icons") return RadixIcons;
        };

        // eslint-disable-next-line no-new-func
        const executionFunction = new Function("require", "module", "exports", transformedCode);
        executionFunction(require, module, module.exports);

        // @ts-ignore
        setComponent(() => module.exports.default as React.ComponentType);
        window.parent.postMessage({ kind: "ARTIFACT_RENDERED", success: true }, "*");
      } catch (error) {
        window.parent.postMessage({ kind: "ARTIFACT_RENDERED", success: false }, "*");
        console.error("Error transforming code:", error);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <Theme>
      <div className="App h-full w-full">
        <ErrorBoundary>
          {Component ? (
            <Component />
          ) : (
            <RadixThemes.Spinner size={"3"} className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          )}
        </ErrorBoundary>
      </div>
    </Theme>
  );
}

export default App;
