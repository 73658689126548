import { Component } from "react";
import { Box, Heading, Text, Button, Code } from "@radix-ui/themes";

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: { componentStack: string } | null;
}

export class ErrorBoundary extends Component<any, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box className="p-4 bg-red-50 border border-red-200 rounded-lg">
          <Heading size="4" className="text-red-700 mb-2">
            Oops! Something went wrong
          </Heading>
          <Text as="p" className="text-red-600 mb-4">
            {this.state.error && this.state.error.toString()}
          </Text>
          <Heading size="3" className="text-red-700 mb-2">
            Error Stack:
          </Heading>
          <Code className="block whitespace-pre-wrap text-sm mb-4 p-2 bg-red-100 rounded">
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </Code>
          <Button
            onClick={() => this.setState({ hasError: false, error: null, errorInfo: null })}
            className="bg-red-600 text-white hover:bg-red-700"
          >
            Try again
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}
